<template>
  <div>
    <v-row class="body-2 mb-3">
      <v-col cols="12">
        <router-link to="/home">
          Home
        </router-link>
        >
        <router-link to="/login">
          Área de login
        </router-link>
        >
        <span>
          Cadastro
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <span class="display-1 text--primary--color font-weight-bold">
            Criar conta
          </span>
      </v-col>
    </v-row>
    <v-row v-if="showDescription">
      <v-col cols="12" sm="6" md="5">
        Aqui você se cadastra como um de nossos compradores e terá acesso a todas as informações
        das ofertas dentro da plataforma.
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    showDescription: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
