<template>
  <v-container class="mkt-content-container mt-10">
    <px-cadastro-comprador-header/>
    <v-subheader class="mt-10 mb-8 body-1 font-weight-bold">
      Dados empresariais
    </v-subheader>
    <v-form @submit.prevent="onClickSave">
      <v-row class="mb-8">
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            :disabled="hasCnpj"
            class="required rounded-card-small"
            autocomplete="off"
            label="CNPJ"
            v-mask="['##.###.###/####-##']"
            :error="$v.empresa.pessoa.cnpj.$error"
            @blur="$v.empresa.pessoa.cnpj.$touch()"
            v-model="empresa.pessoa.cnpj"
          />
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            hide-details
            label="Razão social"
            class="required rounded-card-small"
            maxlength="150"
            :error="$v.empresa.pessoa.nomeReceita.$error"
            @blur="$v.empresa.pessoa.nomeReceita.$touch()"
            v-model="empresa.pessoa.nomeReceita"
          />
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="8">
          <v-text-field
            outlined
            hide-details
            maxlength="100"
            label="Nome fantasia"
            class="required rounded-card-small"
            :error="$v.empresa.pessoa.nome.$error"
            @blur="$v.empresa.pessoa.nome.$touch()"
            v-model="empresa.pessoa.nome"
          />
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            type="email"
            label="E-mail financeiro"
            class="required rounded-card-small"
            maxlength="100"
            :error="$v.empresa.pessoa.emailFinanceiro.$error"
            @blur="$v.empresa.pessoa.emailFinanceiro.$touch()"
            v-model="empresa.pessoa.emailFinanceiro"
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span>
                E-mail para recebimento de boletos e NFe.
              </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            outlined
            hide-details
            type="email"
            label="E-mail empresarial"
            class="required rounded-card-small"
            maxlength="100"
            :error="$v.empresa.pessoa.email.$error"
            @blur="$v.empresa.pessoa.email.$touch()"
            v-model="empresa.pessoa.email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details
            label="Telefone"
            class="required rounded-card-small"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :error="$v.empresa.pessoa.telefone.$error"
            @blur="$v.empresa.pessoa.telefone.$touch()"
            v-model="empresa.pessoa.telefone"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <px-autocomplete-segmento
            outlined
            hide-details
            class="required rounded-card-small"
            v-model="empresa.segmento"/>
      </v-col>
      </v-row>
      <v-subheader class="mt-10 mb-8 body-1 font-weight-bold">
        Endereço da empresa
      </v-subheader>
      <v-row class="mb-8">
        <v-col cols="12" sm="3">
          <v-text-field
            outlined
            hide-details
            label="CEP"
            v-mask="['#####-###']"
            class="required rounded-card-small"
            :error="$v.empresa.endereco.cep.$error"
            @blur="$v.empresa.endereco.cep.$touch()"
            v-model="empresa.endereco.cep"
            @change="onChangeCep"
          />
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="7">
          <v-text-field
            outlined
            hide-details
            label="Logradouro"
            maxlength="100"
            class="required rounded-card-small"
            :error="$v.empresa.endereco.rua.$error"
            @blur="$v.empresa.endereco.rua.$touch()"
            v-model="empresa.endereco.rua"
          />
        </v-col>
      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="2">
          <v-text-field
            outlined
            hide-details
            label="Número"
            class="rounded-card-small"
            maxlength="6"
            v-mask="['######']"
            v-model="empresa.endereco.numero"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            outlined
            hide-details
            label="Bairro"
            maxlength="60"
            class="required rounded-card-small"
            :error="$v.empresa.endereco.bairro.$error"
            @blur="$v.empresa.endereco.bairro.$touch()"
            v-model="empresa.endereco.bairro"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3">
          <px-autocomplete-uf outlined
                              hide-details
                              class="required rounded-card-small"
                              :error="$v.empresa.endereco.estado.$error"
                              @blur="$v.empresa.endereco.estado.$touch()"
                              v-model="empresa.endereco.estado"
                              @change="onChangeUf"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <px-autocomplete-municipio
            outlined
            hide-details
            class="required rounded-card-small"
            :uf="empresa.endereco.estado"
            :disabled="!empresa.endereco.estado"
            :error="$v.empresa.endereco.municipio.$error"
            @blur="$v.empresa.endereco.municipio.$touch()"
            v-model="empresa.endereco.municipio"/>
        </v-col>
      </v-row>
      <v-subheader class="mt-10 mb-8 body-1 font-weight-bold">
        Dados do usuario
      </v-subheader>
      <v-row class="mb-8">
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="Nome"
            class="required rounded-card-small"
            maxlength="150"
            :error="$v.empresa.usuario.nome.$error"
            @blur="$v.empresa.usuario.nome.$touch()"
            v-model="empresa.usuario.nome"
          />
        </v-col>

      </v-row>
      <v-row class="mb-8">
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            hide-details
            label="E-mail"
            type="email"
            name="email"
            class="required rounded-card-small"
            maxlength="150"
            :error="$v.empresa.usuario.email.$error"
            @blur="$v.empresa.usuario.email.$touch()"
            v-model="empresa.usuario.email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            label="Senha"
            class="required rounded-card-small"
            minlength="8"
            maxlength="30"
            :append-icon="passwordIcon(this.showPassword)"
            :type="passwordType(this.showPassword)"
            :messages="passwordMessages"
            autocomplete="new-password"
            :error="$v.empresa.usuario.password.$error"
            @blur="$v.empresa.usuario.password.$touch()"
            v-model="empresa.usuario.password"
            @click:append="changeShowPassword('showPassword')"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            outlined
            label="Repetir senha"
            class="required rounded-card-small"
            minlength="8"
            maxlength="30"
            autocomplete="new-password"
            :append-icon="passwordIcon(this.showMatchPassword)"
            :type="passwordType(this.showMatchPassword)"
            :error="$v.empresa.usuario.matchPassword.$error"
            @blur="$v.empresa.usuario.matchPassword.$touch()"
            v-model="empresa.usuario.matchPassword"
            @click:append="changeShowPassword('showMatchPassword')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox v-model="isChecked"
                      :error="$v.isChecked.$error"
                      @blur="$v.isChecked.$touch()">
            <template v-slot:label>
              <span>
                Declaro que li e aceito os
                <a @click.stop target="_blank" href="https://proxpect.com.br/termos-uso">
                termos de uso
                </a>
                da plataforma.
              </span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn large
                 color="primary"
                 class="normal-btn-text rounded-card-small"
                 type="submit"
                 :loading="loading">
            Criar conta
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {
  required, email, sameAs, minLength, maxLength,
} from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import { isValid } from '@/utils/validate-cnpj';
import CepService from '@/api/cep-service';
import { onlyNumbers } from '@/utils/regex-utils';
import { isPasswordValid } from '../../utils/validate-password';
import CadastroCompradorService from './cadastro-comprador-service';
import CadastroCompradorHeader from './CadastroCompradorHeader.vue';
import PxAutocompleteSegmento from '../../components/PxAutocompleteSegmento.vue';

const CEP_LENGTH = 9;

export default {
  mixins: [validationMixin],
  data() {
    return {
      showPassword: false,
      showMatchPassword: false,
      isChecked: false,
      loading: false,
      passwordMessages: ['Deve possuir letra maiúscula, minúscula e números. Entre 8 e 30 caracteres'],
      empresa: {
        pessoa: {
          cnpj: this.$route.params.cnpj,
          nomeReceita: null,
          nome: null,
          email: null,
          emailFinanceiro: null,
          telefone: null,
        },
        endereco: {
          cep: null,
          rua: null,
          numero: null,
          bairro: null,
          estado: null,
          municipio: null,
        },
        usuario: {
          nome: null,
          email: null,
          password: null,
          matchPassword: null,
        },
        segmento: null,
      },
    };
  },
  computed: {
    hasCnpj() {
      return Boolean(this.$route.params.cnpj);
    },
    passwordType() {
      return (varType) => {
        if (varType) {
          return 'text';
        }
        return 'password';
      };
    },
    passwordIcon() {
      return (varType) => {
        if (varType) {
          return 'mdi-eye-off';
        }
        return 'mdi-eye';
      };
    },
  },
  methods: {
    handleEndereco(data) {
      if (!data || data.erro) {
        this.clearEndereco();
        return;
      }

      this.empresa.endereco.rua = data.rua;
      this.empresa.endereco.bairro = data.bairro;
      this.empresa.endereco.estado = data.estado;
      this.empresa.endereco.municipio = data.municipio;
    },
    getCepData(cep) {
      CepService.get(cep)
        .then(({ data }) => {
          this.handleEndereco(data);
        });
    },
    onChangeCep() {
      if (!this.empresa.endereco.cep || this.empresa.endereco.cep.length !== CEP_LENGTH) {
        return;
      }
      const cep = this.empresa.endereco.cep.match(/\d+/g).join('');
      this.getCepData(cep);
    },
    onChangeUf() {
      this.empresa.endereco.municipio = null;
    },
    buildEmpresa() {
      const result = {
        empresa: {
          pessoa: { ...this.empresa.pessoa },
          endereco: { ...this.empresa.endereco },
          segmento: { ...this.empresa.segmento },
        },
        usuario: { ...this.empresa.usuario },
      };

      result.empresa.pessoa.cnpj = onlyNumbers(result.empresa.pessoa.cnpj);
      result.empresa.endereco.cep = onlyNumbers(result.empresa.endereco.cep);

      return result;
    },
    onClickSave() {
      if (this.validateForm() || !this.isChecked) {
        return;
      }

      this.loading = true;
      CadastroCompradorService.create(this.buildEmpresa())
        .then(() => {
          this.$router.push({ name: 'public.cadastro.sucesso' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeShowPassword(passwordField) {
      this[passwordField] = !this[passwordField];
    },
  },
  validations: {
    isChecked: {
      requireTrue(value) {
        return value;
      },
    },
    empresa: {
      pessoa: {
        nomeReceita: {
          required,
        },
        nome: {
          required,
        },
        telefone: {
          required,
        },
        email: {
          required,
          email,
        },
        emailFinanceiro: {
          required,
          email,
        },
        cnpj: {
          required,
          isValid,
        },
      },
      endereco: {
        cep: {
          required,
        },
        rua: {
          required,
        },
        bairro: {
          required,
        },
        estado: {
          required,
        },
        municipio: {
          required,
        },
      },
      usuario: {
        nome: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(30),
          passwordValid(value) {
            return isPasswordValid(value);
          },
        },
        matchPassword: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
  },
  components: {
    pxCadastroCompradorHeader: CadastroCompradorHeader,
    pxAutocompleteSegmento: PxAutocompleteSegmento,
  },
};
</script>
